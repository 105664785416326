import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { MasterDataModule } from '../../services/masterdata.module';
import { CompartmentsApiEffects } from './compartments-api.effects';
import { CompartmentStateContainer } from './compartments-state.model';
import { CompartmentsEffects } from './compartments.effects';
import { compartmentsReducer } from './compartments.reducer';

@NgModule({
    imports: [
        MasterDataModule,
        StoreModule.forFeature(nameof<CompartmentStateContainer>('compartment'), compartmentsReducer),
        EffectsModule.forFeature([CompartmentsEffects, CompartmentsApiEffects]),
    ],
    providers: [],
})
export class CompartmentsStoreModule {}
