import { createSelector } from '@ngrx/store';

import { AnalysisMetrics, AnomalyMetrics, MetricConfigs } from '@app/metrics';

import { AnalysisState, AnalysisStateContainer } from './analysis-state.model';

export const selectAnalysisState = (state: AnalysisStateContainer) => state.analysis;

export const selectMetricDefinitions = createSelector(selectAnalysisState, (state: AnalysisState) => {
    return state.metricDefinitions;
});

export const selectMetricIds = createSelector(selectMetricDefinitions, (metricDefinitions) => {
    return metricDefinitions ? metricDefinitions.map((definition) => definition.id) : undefined;
});

export const selectCropMetrics = createSelector(selectMetricIds, (metricDefinitions) => {
    if (!metricDefinitions) return undefined;
    return MetricConfigs.filter((config) => metricDefinitions.includes(config.id));
});

export const selectCropAnalysisMetrics = createSelector(selectMetricIds, (metricIds) => {
    if (!metricIds) return undefined;
    return AnalysisMetrics.filter((config) => metricIds.includes(config.id));
});

export const selectAnomalyMetrics = createSelector(selectMetricIds, (metricIds) => {
    if (!metricIds) return undefined;
    return AnomalyMetrics.filter((anomalyMetric) => metricIds.includes(anomalyMetric.id));
});
