import { Compartment } from '../../models';

export interface CompartmentStateContainer {
    compartment: CompartmentState;
}

export interface CompartmentState {
    compartments: Compartment[];
}

export const initialCompartmentState: CompartmentState = { compartments: undefined };
