import { AsyncPipe, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';

import { PrivaFormGroupComponent } from '@priva/components/form-group';
import { PrivaFormInputGroupModule } from '@priva/components/form-input-group';
import { PrivaSelectModule } from '@priva/components/select';
import { PrivaTreeItem, PrivaTreeModule } from '@priva/components/tree';
import {
    Compartment,
    CompartmentsApiService,
    Crop,
    CropLocation,
    Greenhouse,
    loadRowLocationsIfNeeded,
    Row,
    RowsApiService,
    selectGreenhouses,
    selectRowLocationsForCrop,
    selectSites,
    Site,
} from '@priva/masterdata';

interface formCrop {
    site: string;
    greenhouse: string;
    locations: string[];
}

interface LocationTreeItem extends PrivaTreeItem {
    parent?: LocationTreeItem;
    children?: LocationTreeItem[];
    isChecked?: boolean;
    isIndeterminate?: boolean;
}

@Component({
    selector: 'app-crop-location',
    templateUrl: './crop-location.component.html',
    styleUrls: ['./crop-location.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        PrivaFormGroupComponent,
        PrivaSelectModule,
        NgClass,
        PrivaFormInputGroupModule,
        PrivaTreeModule,
        AsyncPipe,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropLocationComponent implements OnInit, OnDestroy {
    private readonly _store = inject(Store);
    private readonly _compartmentsService = inject(CompartmentsApiService);
    private readonly _rowsService = inject(RowsApiService);

    private readonly _unsubscribe$: Subject<void> = new Subject<void>();

    private _compartments: LocationTreeItem[];

    @Input() public crop: Crop;

    @Output() public validChange: EventEmitter<{ isValid: boolean; crop: Crop; locations: CropLocation[] }> =
        new EventEmitter();

    public form: FormGroup;
    public locations: CropLocation[] = [];
    public sites$: Observable<Site[]>;
    public greenhouses$: Observable<Greenhouse[]>;
    public filterGreenhouses$ = new BehaviorSubject<string>('');
    public filterLocations$ = new BehaviorSubject<string>('');

    public filteredLocationTreeItems$: Observable<LocationTreeItem[]>;

    public get siteControl(): AbstractControl {
        return this.form.controls.site;
    }

    public get greenhouseControl(): AbstractControl {
        return this.form.controls.greenhouse;
    }

    public ngOnInit(): void {
        this.getLocations();
        this.createForm();
        this.initializeStreams();
    }

    public ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    public getLocations() {
        if (this.crop?.id) {
            this._store.dispatch(loadRowLocationsIfNeeded({ cropId: this.crop?.id }));
        }

        this._store
            .select(selectRowLocationsForCrop(this.crop?.id))
            .pipe(
                filter((rowLocations) => !!rowLocations),
                takeUntil(this._unsubscribe$),
                tap((rowLocations) => {
                    this.locations = rowLocations.map((rowLocation): CropLocation => {
                        return {
                            rowId: rowLocation.id,
                            rowPartId: undefined,
                        };
                    });
                }),
            )
            .subscribe();
    }

    public onTreeItemToggle(item: LocationTreeItem) {
        item.isChecked = !item.isChecked;
        item.isIndeterminate = false;

        if (item.type === 'compartment') {
            // Set all rows to the same state as the compartment
            item.children?.forEach((row: LocationTreeItem) => (row.isChecked = item.isChecked));
        } else if (item.type === 'row') {
            this.updateCompartmentTreeItem(item.parent);
        }

        const selectedRows = this._compartments
            .flatMap((compartment) => compartment.children)
            .filter((row) => row.isChecked);
        this.form.controls.locations.setValue(selectedRows.map((row) => row.id));
    }

    private createForm(): void {
        const cropFormValues = this.getFormValuesFromCrop();
        const locationsFormValues = this.getFormValuesFromLocations();
        this.form = new FormGroup({
            site: new FormControl(cropFormValues.site, {
                validators: [Validators.required],
            }),
            greenhouse: new FormControl(cropFormValues.greenhouse, [Validators.required]),
            locations: new FormControl<string[] | null>(locationsFormValues.locations, [Validators.required]),
        });

        this.initializeControls();

        this.watchSiteChanges();
        this.watchGreenhouseChanges();
        this.watchValidityChanges();
        this.form.updateValueAndValidity();
    }

    private initializeStreams() {
        this.initializeSitesStream();
        this.initializeGreenhousesStream();

        if (this.crop?.siteId && this.crop?.greenhouseId) {
            this.initializeLocationsStreams();
        }
    }

    private watchSiteChanges(): void {
        this.form.controls.site.valueChanges
            .pipe(
                takeUntil(this._unsubscribe$),
                map(() => {
                    // Reset greenhouse and locations
                    this.form.controls.greenhouse.reset();
                    this.form.controls.greenhouse.enable();
                    this.form.controls.locations.disable();
                    this.filterGreenhouses$.next(this.form.controls.site.value);
                }),
            )
            .subscribe();
    }

    private watchGreenhouseChanges(): void {
        this.form.controls.greenhouse.valueChanges
            .pipe(
                takeUntil(this._unsubscribe$),
                map(() => {
                    this.form.controls.locations.reset();
                    this.form.controls.locations.enable();
                    this.initializeLocationsStreams();
                }),
            )
            .subscribe();
    }

    private watchValidityChanges(): void {
        this.form.statusChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((status) => {
            const validChangeObject = {
                isValid: status === 'VALID',
                crop: this.getCropFromFormValues(),
                locations: this.getLocationsFromFormValues(),
            };
            this.validChange.emit(validChangeObject);
        });
    }

    private initializeControls() {
        this.form.controls.site.enable();
        this.form.controls.greenhouse.disable();
        this.form.controls.locations.disable();

        if (this.form.controls.site.value) {
            this.form.controls.greenhouse.enable();
        }

        if (this.form.controls.greenhouse.value) {
            this.form.controls.locations.enable();
        }
    }

    private initializeSitesStream() {
        this.sites$ = this._store.select(selectSites).pipe(
            takeUntil(this._unsubscribe$),
            filter((sites) => !!sites),
            map((sites) =>
                [...sites].sort((site1, site2) =>
                    site1.name.localeCompare(site2.name, undefined, { numeric: true }),
                ),
            ),
        );
    }

    private initializeGreenhousesStream() {
        // NOTE: the greenhouses from the store are all the greenhouses for all the sites.
        // We want to have a stream that only contains the greenhouses for the selected site.
        // That's why we use the updateGreenhouses$ subject, to trigger this stream to filter.

        // If the site already has a value...
        if (this.form.controls.site.value) {
            // ...then we need to trigger the stream directly.
            this.filterGreenhouses$.next(this.form.controls.site.value);
        }

        // Make a stream that contains all greenhouses for all sites from the store
        const greenhouses$ = this._store
            .select(selectGreenhouses)
            .pipe(filter((greenhouses) => !!greenhouses));

        // Combine this stream with the trigger stream so that we can filter the greenhouses based on the selected site
        this.greenhouses$ = combineLatest([greenhouses$, this.filterGreenhouses$]).pipe(
            map(([greenhouses, siteId]) => greenhouses.filter((greenhouse) => greenhouse.siteId === siteId)),
            map((greenhouses) =>
                [...greenhouses].sort((greenhouse1, greenhouse2) =>
                    greenhouse1.name.localeCompare(greenhouse2.name, undefined, { numeric: true }),
                ),
            ),
        );
    }

    private initializeLocationsStreams() {
        // A location is a combination of compartments and rows. So fetch both...
        const compartments$: Observable<Compartment[]> =
            this.form.controls.site.value && this.form.controls.greenhouse.value
                ? this._compartmentsService.getCompartments(
                      this.form.controls.site.value,
                      this.form.controls.greenhouse.value,
                  )
                : of([]);

        const rows$: Observable<Row[]> =
            this.form.controls.site.value && this.form.controls.greenhouse.value
                ? this._rowsService.getRows(
                      this.form.controls.site.value,
                      this.form.controls.greenhouse.value,
                  )
                : of([]);

        // ... and combine to location items
        this.filteredLocationTreeItems$ = combineLatest([compartments$, rows$, this.filterLocations$]).pipe(
            map(([compartments, rows, filterValue]) => {
                // Save compartments also for internal usage
                this._compartments = compartments
                    .filter(
                        (compartment) =>
                            compartment.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1,
                    )
                    .sort((compartment1, compartment2) =>
                        compartment1.name.localeCompare(compartment2.name, undefined, { numeric: true }),
                    )
                    .map((compartment) => {
                        const compartmentTreeItem = this.createCompartment(compartment);
                        this.createCompartmentRows(compartmentTreeItem, rows, compartment);

                        this.updateCompartmentTreeItem(compartmentTreeItem);
                        compartmentTreeItem.isExpanded = compartmentTreeItem.isIndeterminate;
                        return compartmentTreeItem;
                    });

                return this._compartments;
            }),
        );
    }

    private createCompartment(compartment: Compartment) {
        return {
            id: compartment.id,
            text: compartment.name,
            type: 'compartment',
            isVisible: true,
            isChecked: false,
            isDisabled: false,
            parent: null,
        } as LocationTreeItem;
    }

    private createCompartmentRows(
        compartmentTreeItem: LocationTreeItem,
        rows: Row[],
        compartment: Compartment,
    ) {
        compartmentTreeItem.children = rows
            .filter((row) => row.compartmentId === compartment.id)
            .sort((row1, row2) => row1.name.localeCompare(row2.name, undefined, { numeric: true }))
            .map((row) => {
                return {
                    id: row.id,
                    text: row.name,
                    type: 'row',
                    parentId: compartment.id,
                    isVisible: true,
                    isChecked: !!this.locations?.find((location) => location.rowId === row.id),
                    parent: compartmentTreeItem,
                } as LocationTreeItem;
            });
    }

    private updateCompartmentTreeItem(compartmentTreeItem: LocationTreeItem) {
        if (compartmentTreeItem.children.length === 0) {
            return;
        }

        // The containing compartment is checked when all rows are checked...
        compartmentTreeItem.isChecked = compartmentTreeItem.children.every((row) => row.isChecked);
        // ... and indeterminate when not checked and at least 1 row is selected
        compartmentTreeItem.isIndeterminate =
            !compartmentTreeItem.isChecked && compartmentTreeItem.children.some((row) => row.isChecked);
    }

    private getFormValuesFromCrop(): formCrop {
        return {
            site: this.crop?.siteId,
            greenhouse: this.crop?.greenhouseId,
        } as formCrop;
    }

    private getFormValuesFromLocations(): formCrop {
        return {
            locations: this.locations?.map((location) => location.rowId),
        } as formCrop;
    }

    private getCropFromFormValues(): Crop {
        const formValues = this.form.getRawValue();
        return {
            ...this.crop,
            siteId: formValues.site,
            greenhouseId: formValues.greenhouse,
        } as Crop;
    }

    private getLocationsFromFormValues(): CropLocation[] {
        if (this.form.controls.locations.value) {
            return this.form.controls.locations.value.map(
                (rowId: string): CropLocation => ({
                    rowId,
                    rowPartId: '00000000-0000-0000-0000-000000000000',
                }),
            );
        }

        return null;
    }
}
