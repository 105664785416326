import { createSelector } from '@ngrx/store';

import { CompartmentState, CompartmentStateContainer } from './compartments-state.model';

export const selectCompartmentState = (state: CompartmentStateContainer) => state.compartment;

export const selectCompartments = createSelector(
    selectCompartmentState,
    (state: CompartmentState) => state.compartments,
);
