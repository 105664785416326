import { createSelector } from '@ngrx/store';

import { DashboardPlanKpiConfigs, KpiConfigs, KpiDetailsConfigs } from '@app/kpis';

import { MonitoringState, MonitoringStateContainer } from './monitoring-state.model';

export const selectMonitoringState = (state: MonitoringStateContainer) => state.monitoring;

export const selectKpiDefinitions = createSelector(selectMonitoringState, (state: MonitoringState) => {
    return state.kpiDefinitions;
});

export const selectKpiDefinitionsIds = createSelector(selectKpiDefinitions, (kpiDefinitions) => {
    return kpiDefinitions ? kpiDefinitions.map((kpiDefinition) => kpiDefinition.id) : undefined;
});

export const selectCropKpis = createSelector(selectKpiDefinitionsIds, (kpiDefinitions) => {
    if (!kpiDefinitions) return undefined;
    return KpiConfigs.filter((config) => kpiDefinitions.includes(config.id));
});

export const selectDashboardKpis = createSelector(selectKpiDefinitionsIds, (kpiIds) => {
    if (!kpiIds) return undefined;
    return DashboardPlanKpiConfigs.filter((config) => kpiIds.includes(config.id));
});

export const selectPlanKpis = createSelector(selectKpiDefinitionsIds, (kpiIds) => {
    if (!kpiIds) return undefined;
    return DashboardPlanKpiConfigs.filter((config) => kpiIds.includes(config.id));
});

export const selectKpiChartsKpis = createSelector(selectKpiDefinitionsIds, (kpiIds) => {
    if (!kpiIds) return undefined;
    return KpiDetailsConfigs.filter((config) => kpiIds.includes(config.id)).reverse();
});
