import { inject, Injectable } from '@angular/core';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

import { LocaleService } from '../locale/locale.service';
import {
    DateFormat,
    DayFormat,
    MonthFormat,
    MonthYearFormat,
    WeekDayFormat,
    YearFormat,
} from './date-constants';

@Injectable({
    providedIn: 'root',
})
export class DatePickerService {
    private readonly _localeService = inject(LocaleService);
    private readonly _defaultConfig: IDatePickerConfig = {
        format: DateFormat.toUpperCase(),
        firstDayOfWeek: 'mo',
        dayBtnFormat: DayFormat,
        weekDayFormat: WeekDayFormat,
        monthFormat: MonthYearFormat,
        monthBtnFormat: MonthFormat,
        yearFormat: YearFormat,
        showWeekNumbers: true,
        closeOnSelect: true,
        showGoToCurrent: false,
        unSelectOnClick: false,
        enableMonthSelector: true,
        disableKeypress: true,
        returnedValueType: ECalendarValue.String,
    };

    private readonly _locale: string;

    constructor() {
        this._locale = this._localeService.language;
    }

    public createConfig(options?: IDatePickerConfig): IDatePickerConfig {
        return {
            ...this._defaultConfig,
            ...options,
            locale: this._locale,
        } as IDatePickerConfig;
    }
}
