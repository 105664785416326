import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AppConfigurationService, dateToLocalDateString } from '@app/utilities';

import { CropMonitoringApiService } from '../crop-monitoring.api.service';
import { KpiTargetsResponse } from './kpi-targets.model';

@Injectable({
    providedIn: 'root',
})
export class KpiTargetsApiService extends CropMonitoringApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.kpiTargetsService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    private replaceCropId(cropId: string): string {
        return (this._baseUri = this._baseUri.replace(':cropId', cropId));
    }

    private resetBaseUri(): void {
        this._baseUri = this._appConfigurationService.configuration.uris.kpiTargetsService;
    }

    public getKpiTargets(
        cropId: string,
        kpiIds?: string[],
        start?: Dayjs,
        end?: Dayjs,
    ): Observable<KpiTargetsResponse> {
        this.resetBaseUri();
        this.replaceCropId(cropId);
        let params = new HttpParams();
        if (Array.isArray(kpiIds)) {
            kpiIds.forEach((kpiId) => {
                params = params.append('kpiIds', kpiId);
            });
        } else {
            params = params.append('kpiIds', kpiIds);
        }

        if (start) {
            params = params.append('localStartDate', dateToLocalDateString(start));
            params = params.append('localEndDate', dateToLocalDateString(end));
        }

        return this.get<KpiTargetsResponse>('', [404], params);
    }

    public updateKpiTargets(cropId: string, kpiTargets: KpiTargetsResponse): Observable<KpiTargetsResponse> {
        this.resetBaseUri();
        this.replaceCropId(cropId);
        return this.put<KpiTargetsResponse>('', kpiTargets);
    }
}
