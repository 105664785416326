import { createReducer, on } from '@ngrx/store';

import { CompartmentState, initialCompartmentState } from './compartments-state.model';
import { setCompartments } from './compartments.actions';

export const compartmentsReducer = createReducer(
    initialCompartmentState,
    on(setCompartments, (state, action): CompartmentState => {
        return {
            ...state,
            compartments: action.compartments,
        };
    }),
);
