import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { Compartment } from '../../models';
import { CompartmentsApiService } from '../../services';
import { loadCompartments, loadCompartmentsSuccess } from './compartments.actions';

@Injectable({
    providedIn: 'root',
})
export class CompartmentsApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _compartmentsService = inject(CompartmentsApiService);

    /**
     * This effect will really request the compartments from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadCompartments$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCompartments),
            mergeMap(() => {
                return this._compartmentsService.getCompartments().pipe(
                    map((compartments: Compartment[]) =>
                        loadCompartmentsSuccess({ compartments: compartments }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}
