import { of } from 'rxjs';

export const privaLocalizationServiceMock = {
    instant: (resourceId: string) => resourceId,
    translate: (key: string) => of(key),
    language: of('en-US'),
};

// Mock services
export class MockStore {
    selectSignal = jest.fn().mockReturnValue(() => null);
    dispatch = jest.fn();
}

export class MockRouter {
    navigate = jest.fn();
}

export class MockPrivaSegmentService {
    track = jest.fn();
}

export class MockLocaleService {
    language = 'en';
}

export class MockDatePickerService {
    createConfig = jest.fn().mockReturnValue({});
}

export class MockPrivaLocalizationService {
    constructor() {
        // Do not inject TranslateService
    }
    instant = jest.fn().mockImplementation((key) => key);
}
