import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AppConfigurationService, dateToLocalDateString } from '@app/utilities';

import { CropMonitoringApiService } from '../crop-monitoring.api.service';
import { AnomaliesResponse } from './anomalies.model';

@Injectable({
    providedIn: 'root',
})
export class AnomaliesApiService extends CropMonitoringApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.anomaliesService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    private replaceCropId(cropId: string): string {
        return (this._baseUri = this._baseUri.replace(':cropId', cropId));
    }

    private resetBaseUri(): void {
        this._baseUri = this._appConfigurationService.configuration.uris.anomaliesService;
    }

    public getDayAnomalies(
        cropId: string,
        metricIds: string[],
        start: Dayjs,
        end: Dayjs,
    ): Observable<AnomaliesResponse> {
        this.setRequest(cropId);
        const localStartDate = dateToLocalDateString(start);
        const localEndDate = dateToLocalDateString(end);
        const params = this.getParams(metricIds, localStartDate, localEndDate);

        return this.get<AnomaliesResponse>(`dates`, [], params);
    }

    private getParams(metricIds: string[], localStartDate: string, localEndDate: string): HttpParams {
        let params = new HttpParams();

        metricIds.forEach((metricId) => (params = params.append('metricIds', metricId)));
        params = params.append('localStartDate', localStartDate);
        params = params.append('localEndDate', localEndDate);
        return params;
    }

    private setRequest(cropId: string) {
        this.resetBaseUri();
        this.replaceCropId(cropId);
    }
}
