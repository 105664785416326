import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { CompartmentStateContainer } from './compartments-state.model';
import {
    loadCompartments,
    loadCompartmentsIfNeeded,
    loadCompartmentsSuccess,
    setCompartments,
} from './compartments.actions';

@Injectable({
    providedIn: 'root',
})
export class CompartmentsEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<CompartmentStateContainer> = inject(Store);

    /**
     * This effect checks if the compartments are already in the store.
     * If not: then it will fire an action to really load the compartments
     */
    public loadCompartmentsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCompartmentsIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                if (!state.compartment.compartments) {
                    // We don't have the compartments, so request it
                    return of(loadCompartments());
                } else {
                    // We have the compartments already present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will fire an action to save this result in the store.
     */
    public loadCompartmentsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCompartmentsSuccess),
            mergeMap((action) => {
                return of(setCompartments({ compartments: action.compartments }));
            }),
        );
    });
}
