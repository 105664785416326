import { inject, Injectable } from '@angular/core';
import { take } from 'rxjs';

import { PrivaLocalizationService } from '@priva/localization';

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    private readonly _localizationService = inject(PrivaLocalizationService);
    private readonly _localeDecimalSeparator: string;
    private _language = 'en';

    constructor() {
        const number = 1.1;
        this._localeDecimalSeparator = number.toLocaleString().substring(1, 2);
        this._localizationService.language.pipe(take(1)).subscribe((language: string) => {
            this._language = language.substring(0, 2);
        });
    }

    public get localeDecimalSeparator(): string {
        return this._localeDecimalSeparator;
    }

    public get language(): string {
        return this._language;
    }
}
