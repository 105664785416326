import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AppConfigurationService, dateToLocalDateString } from '@app/utilities';

import { CropMonitoringApiService } from '../crop-monitoring.api.service';
import { KpisDateResponse, KpisResponse, KpisWeekResponse } from './kpis.api.model';

@Injectable({
    providedIn: 'root',
})
export class KpisApiService extends CropMonitoringApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.kpisService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    public getKpisDate(
        localStartDate: Dayjs,
        localEndDate: Dayjs,
        kpiIds: string[],
        cropId: string,
    ): Observable<KpisDateResponse> {
        this.setRequest(cropId);
        let params = this.getParams(kpiIds);
        params = params.append('localStartDate', dateToLocalDateString(localStartDate));
        params = params.append('localEndDate', dateToLocalDateString(localEndDate));
        return this.get<KpisDateResponse>('dates/', [404], params);
    }

    public getKpisWeek(
        localStartYearWeek: string,
        localEndYearWeek: string,
        kpiIds: string[],
        cropId: string,
    ): Observable<KpisWeekResponse> {
        this.setRequest(cropId);
        let params = this.getParams(kpiIds);
        params = params.append('localStartYearWeek', localStartYearWeek.toString());
        params = params.append('localEndYearWeek', localEndYearWeek.toString());
        return this.get<KpisWeekResponse>('weeks/', [404], params);
    }

    public updateKpis(cropId: string, kpis: KpisResponse): Observable<KpisResponse> {
        this.resetBaseUri();
        this.replaceCropId(cropId);
        return this.put<KpisResponse>('', kpis);
    }

    private getParams(kpiIds: string[]): HttpParams {
        let params = new HttpParams();
        if (Array.isArray(kpiIds)) {
            kpiIds.forEach((kpiId) => {
                params = params.append('kpiIds', kpiId);
            });
        } else {
            params = params.append('kpiIds', kpiIds);
        }
        return params;
    }

    private replaceCropId(cropId: string): string {
        return (this._baseUri = this._baseUri.replace(':cropId', cropId));
    }

    private resetBaseUri(): void {
        this._baseUri = this._appConfigurationService.configuration.uris.kpisService;
    }

    private setRequest(cropId: string) {
        this.resetBaseUri();
        this.replaceCropId(cropId);
    }
}
