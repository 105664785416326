import { Colors } from '../charts/chart-series-configs';
import { MetricConfig } from './metric-config.interface';
import { Metric } from './metric.enum';

export const Temp24hConfig: MetricConfig = {
    color: Colors['green1'],
    label: 'APP.ANALYSIS.TEMPERATURE.INSIDE',
    shortLabel: 'APP.METRIC.TEMP_INSIDE_SHORT',
    testId: 'temperature-inside',
    id: Metric.Temp24h,
    kpiId: Metric.Temp24h,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const TempDayConfig: MetricConfig = {
    color: Colors['red2'],
    label: 'APP.ANALYSIS.TEMPERATURE.DAY.INSIDE',
    shortLabel: 'APP.METRIC.TEMP_DAY_SHORT',
    testId: 'temperature-day',
    id: Metric.TempDay,
    kpiId: Metric.TempDay,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const TempNightConfig: MetricConfig = {
    color: Colors['red1'],
    label: 'APP.ANALYSIS.TEMPERATURE.NIGHT.INSIDE',
    shortLabel: 'APP.METRIC.TEMP_NIGHT_SHORT',
    testId: 'temperature-night',
    id: Metric.TempNight,
    kpiId: Metric.TempNight,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const RadSumOut24hConfig: MetricConfig = {
    color: Colors['orange2'],
    label: 'APP.METRIC.RAD',
    shortLabel: 'APP.METRIC.RAD_SHORT',
    testId: 'radiation-outside',
    id: Metric.RadSumOut24h,
    kpiId: Metric.RadSumOut24h,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

export const CumRadSumOutConfig: MetricConfig = {
    color: Colors['red3'],
    label: 'APP.METRIC.CUM_RAD',
    shortLabel: 'APP.METRIC.CUM_RAD_SHORT',
    testId: 'radiation-outside-sum',
    id: Metric.CumRadSumOut,
    kpiId: Metric.CumRadSumOut,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

// export const RTRatioAvg24hrConfig: MetricConfig = {
//     color: Colors['blue1'],
//     label: 'APP.ANALYSIS.RTR',
//     testId: 'RTR',
//     id: Metric.RTRatioAvg24hr,
//     decimals: 0,
//     unit: 'APP.UNIT.CELSIUS',
// };

export const WaterUptakeConfig: MetricConfig = {
    color: Colors['blue2'],
    label: 'APP.ANALYSIS.WATER_UPTAKE',
    shortLabel: 'APP.ANALYSIS.WATER_UPTAKE',
    testId: 'water-uptake',
    id: Metric.WaterUptake,
    kpiId: Metric.WaterUptake,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.LITER_PER_SQUARE_METER',
};

export const Co224hConfig: MetricConfig = {
    color: Colors['grey2'],
    label: 'APP.METRIC.HR24_CO2',
    shortLabel: 'APP.METRIC.HR24_CO2_SHORT',
    testId: 'co2concentration-24h',
    id: Metric.Co224h,
    kpiId: Metric.Co224h,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.PARTS_PER_MILLION',
};

export const Co2DayConfig: MetricConfig = {
    color: Colors['grey1'],
    label: 'APP.METRIC.DAY_CO2',
    shortLabel: 'APP.METRIC.DAY_CO2_SHORT',
    testId: 'co2concentration-day',
    id: Metric.Co2Day,
    kpiId: Metric.Co2Day,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.PARTS_PER_MILLION',
};

export const RH24hConfig: MetricConfig = {
    color: Colors['green3'],
    label: 'APP.METRIC.HR24_RH',
    shortLabel: 'APP.METRIC.HR24_RH_SHORT',
    testId: 'rh24h',
    id: Metric.RH24h,
    kpiId: Metric.RH24h,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.PERCENTAGE',
};

export const RadSum24hConfig: MetricConfig = {
    color: Colors['orange1'],
    label: 'APP.METRIC.HR24RAD_SUM',
    shortLabel: 'APP.METRIC.HR24RAD_SUM_SHORT',
    testId: 'radiation-inside',
    id: Metric.RadSum24h,
    kpiId: Metric.RadSum24h,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

export const WeekRadSumConfig: MetricConfig = {
    color: Colors['orange3'],
    label: 'APP.METRIC.WKRAD_SUM',
    shortLabel: 'APP.METRIC.WKRAD_SUM_SHORT',
    testId: 'radiation-inside-sum',
    id: Metric.WeekRadSum,
    kpiId: Metric.WeekRadSum,
    decimals: 0,
    isWeekly: true,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

export const ECDrainConfig: MetricConfig = {
    color: Colors['brown1'],
    label: 'APP.METRIC.EC_DRAIN',
    shortLabel: 'APP.ANALYSIS.IRRIGATION_EC',
    testId: 'ec-drain',
    id: Metric.ECDrain,
    kpiId: Metric.ECDrain,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.MILLISIEMENS_PER_CENTIMETER',
};

export const PhDrainConfig: MetricConfig = {
    color: Colors['brown2'],
    label: 'APP.METRIC.PH_DRAIN',
    shortLabel: 'APP.ANALYSIS.IRRIGATION_PH',
    testId: 'ph-drain',
    id: Metric.PHDrain,
    kpiId: Metric.PHDrain,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.PH',
};

export const RTRParConfig: MetricConfig = {
    color: Colors['purple1'],
    label: 'APP.METRIC.RTR_PAR',
    shortLabel: 'APP.METRIC.RTR_PAR_SHORT',
    testId: 'rtr-par-sum',
    id: Metric.RTRPar,
    kpiId: Metric.RTRPar,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS_PER_10_MOL_PER_SQUARE_METER',
};

export const RTRRadSumConfig: MetricConfig = {
    color: Colors['purple1'],
    label: 'APP.METRIC.RTR_RAD_SUM',
    shortLabel: 'APP.METRIC.RTR_RAD_SUM_SHORT',
    testId: 'rtr-rad-sum',
    id: Metric.RTRRadSum,
    kpiId: Metric.RTRRadSum,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const DoseConfig: MetricConfig = {
    color: Colors['blue1'],
    label: 'APP.METRIC.DOSE',
    shortLabel: 'APP.METRIC.DOSE',
    testId: 'dose',
    id: Metric.Dose,
    kpiId: Metric.Dose,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.LITER_PER_SQUARE_METER',
};

export const DrainConfig: MetricConfig = {
    color: Colors['brown3'],
    label: 'APP.METRIC.DRAIN',
    shortLabel: 'APP.METRIC.DRAIN',
    testId: 'drain',
    id: Metric.Drain,
    kpiId: Metric.Drain,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.LITER_PER_SQUARE_METER',
};

export const YieldConfig: MetricConfig = {
    color: Colors['green3'],
    label: 'APP.METRIC.YIELD',
    shortLabel: 'APP.METRIC.YIELD_SHORT',
    testId: 'yield',
    id: Metric.Yield,
    kpiId: Metric.Yield,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.KILOGRAM_PER_SQUARE_METER',
};

export const SettingSpeedConfig: MetricConfig = {
    color: Colors['brown3'],
    label: 'APP.METRIC.SETTING_SPEED',
    shortLabel: 'APP.METRIC.SETTING_SPEED_SHORT',
    testId: 'setting-speed',
    id: Metric.SettingSpeed,
    kpiId: Metric.SettingSpeed,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.TRUSSES_PER_SQUARE_METER_PER_WEEK',
};

export const SetHarvestDaysConfig: MetricConfig = {
    color: Colors['orange3'],
    label: 'APP.METRIC.SET_HARVEST_DAYS',
    shortLabel: 'APP.METRIC.SET_HARVEST_DAYS_SHORT',
    testId: 'set-harvest-days',
    id: Metric.SetHarvestDays,
    kpiId: Metric.SetHarvestDays,
    decimals: 0,
    isWeekly: true,
    unit: 'APP.UNIT.DAYS',
};
