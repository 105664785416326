import { createAction, props } from '@ngrx/store';

import { Compartment } from '../../models';

export const loadCompartmentsIfNeeded = createAction('[Compartments] Load compartments If Needed');

export const loadCompartments = createAction('[Compartments] Load compartments');

export const loadCompartmentsSuccess = createAction(
    '[Compartments] Load compartments success',
    props<{ compartments: Compartment[] }>(),
);

export const setCompartments = createAction(
    '[Compartments] Set compartments',
    props<{ compartments: Compartment[] }>(),
);
