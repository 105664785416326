import { PrivaVariableRequestBase } from '@priva/variables';

export const KPIS_REQUEST_TYPE = 'kpis';

export interface KpisDateRequest {
    tenantId: string;
    cropId: string;
    kpiIds?: string[];
    localStartDate: string; // format: yyyy-MM-dd
    localEndDate: string; // format: yyyy-MM-dd
}

export interface KpisWeekRequest {
    tenantId: string;
    cropId: string;
    kpiIds?: string[];
    localStartYearWeek: string; // format: yyyy-ww
    localEndYearWeek: string; // format: yyyy-ww
}

export interface KpisRequest extends PrivaVariableRequestBase {
    type: string;
    kpiId: string;
    cropId: string;
    parentId?: string;
}

export interface KpisDateResponse {
    kpiValues: Kpi[];
    request: KpisDateRequest;
}

export interface KpisWeekResponse {
    kpiValues: Kpi[];
    request: KpisWeekRequest;
}

export type KpisResponse = KpisDateResponse | KpisWeekResponse;

export interface Kpi {
    kpiId: string;
    values: Value[];
}

export interface Value {
    localDate: string; // format: yyyy-MM-dd
    value: number;
    target: {
        value: number;
        bandMinValue: number;
        bandMaxValue: number;
    };
}
