import { DashboardMetrics, KpiDetailMetrics, MetricConfig, MetricConfigs } from '@app/metrics';

import { KpiConfig } from './kpi-config-interface';

export const KpiConfigs: KpiConfig[] = mapMetricToKpiConfig(
    MetricConfigs.filter((metricConfig) => !!metricConfig.kpiId),
);

export const DashboardPlanKpiConfigs = mapMetricToKpiConfig(DashboardMetrics);

export const KpiDetailsConfigs = mapMetricToKpiConfig(KpiDetailMetrics);

function mapMetricToKpiConfig(metricConfigs: MetricConfig[]): KpiConfig[] {
    return metricConfigs.map(({ id, testId, kpiId, isWeekly, unit, shortLabel, label, decimals, color }) => {
        return {
            id: kpiId,
            testId,
            label,
            shortLabel,
            color,
            decimals,
            isWeekly,
            unit,
            metricId: id,
        };
    });
}
